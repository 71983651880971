import React, { Component } from 'react';
import $ from 'jquery';
import { RotatingLines } from 'react-loader-spinner';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import '../reports/master_Dashbord.scss';
import ApiUrlCall from '../API/apiurl';
import html2canvas from "html2canvas";
import CommonFunction from '../common_function';
import Calendar from 'react-calendar/dist/entry.nostyle';
import analytics from '../../Config/firebase';
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { withTranslation } from 'react-i18next';
import Timekeeper from 'react-timekeeper';
import { Route, Redirect, Switch, Link, BrowserRouter as Router } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Mumbai_reports from '../reports/Master_dashboard_mumbai'; // Adjust the path based on your current file location




const initialState = {
 dateRange: {}
};


class IMD_reports extends Component {
 constructor(props) {
   super(props);


   const currentDate = new Date();
  // const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
   const startdatetwoDaysAgo = new Date(currentDate);
   startdatetwoDaysAgo.setDate(currentDate.getDate() - 3);
   this.state = {
     isVisible: false,
       mumbai_dash_data: [], // Data specific to Mumbai
  rajasthan_dash_data: [], // Data specific to Rajasthan
     //master_dash_data: [],
     indusdata: [],
     selectedCircle: 'Rajasthan', // Default value set here
     selectedArea: '',
     selectedCluster: '',
     selectedFse: '',
     visibleFourthTableIndex: null,
     date: new Date(),
     counter: 0,
     dateRange: initialState,
     startDate: startdatetwoDaysAgo,
     endDate: currentDate,
     openAlert: false,
     vehicle_name: '',
     message_vehicle: '',
     dateRange: initialState,
     sevenDayFlag: true,
     startTime: "12:00 am",
     sendStartTime: "00:00",
     showStartTime: false,
     endTime: "11:59 pm",
     sendEndTime: "23:59",
     showEndTime: false,
     generateReport: false,
     counter: 0,
     redirectToReferrer: false,
     dg_status: 'All',
     visibleAomIndex: null,
     visibleFseIndex: null,
     visibleCircleIndex: null,
     periodTypeList: [
       { id: 1, name: 'TODAY' },
       { id: 2, name: 'LAST 3 DAYS' },
       { id: 3, name: 'LAST 5 DAYS' },
       { id: 4, name: 'LAST 10 DAYS' },
       { id: 5, name: 'LAST 15 DAYS' },
       { id: 6, name: 'LAST 30 DAYS' },
       { id: 7, name: 'CUSTOM' },
     ],
     period_type: 'CUSTOM',
     circle_type: "",


     sendEmail: false,
     emailaddress: "",
     openAlert: false,
     message: '',
     sevenDayFlag: true,
     showCalender: false,
     recentReportList: [],
     nextCount: 5,
     list: [],
     serialNoList: [],
     allchecked: true,
     driverNameList: "",
     checkbox_value_forDriver: [],
     flag: true,
     checked: true,
     isLoading: false,
     changeStatus: "Download",
     isStartDateSelected: false,
     isEndDateSelected: false,
     areaData: [],
     selectedCircle: '',
     selectedFse: '',
   };
   this.toggleVisibility = this.toggleVisibility.bind(this);
   this.toggleFseVisibility = this.toggleFseVisibility.bind(this);
 }


 componentDidMount() {
   analytics.logEvent('screen_view', {
     screen_name: "TripReport"
   });
   analytics.logEvent('page_view', {
     page_title: "TripReport",
   });
   Amplify.configure(awswebapp);
   Analytics.record({
     name: constant.awsName,
     attributes: { screen_name: 'WEB_TRIP_REPORT' }
   });
 //  this.dgCluster_reports();
   this.dgCluster_reports_new();

   // Set default value for selectedCircle and call imd_dashbord
     this.setState({ selectedCircle: 'Rajasthan' }, this.imd_dashbord);  //Rajasthan
 }


 toggleVisibility = (aomIndex) => {
   this.setState({ visibleAomIndex: this.state.visibleAomIndex === aomIndex ? null : aomIndex });
 };

 toggleFseVisibility = (fseIndex, event) => {
  if (event && typeof event.stopPropagation === 'function') {
    event.stopPropagation();
  }
  this.setState({ visibleFseIndex: this.state.visibleFseIndex === fseIndex ? null : fseIndex });
};


toggleTechnicianVisibility = (index, technicians) => {
  this.setState({
    visibleTechnicianIndex: this.state.visibleTechnicianIndex === index ? null : index,
    selectedTechnicianData: technicians,
  });
};

//  toggleFseVisibility = (fseIndex, event) => {
//    event.stopPropagation();
//    this.setState({ visibleFseIndex: this.state.visibleFseIndex === fseIndex ? null : fseIndex });
//  };

 toggleClusterVisibility = (index, clusters) => {
  this.setState({
    visibleClusterIndex: this.state.visibleClusterIndex === index ? null : index,
    selectedClusterData: clusters,
    visibleFseIndex: null, // Reset the FSE table visibility
    selectedFseData: [], // Reset the FSE data
  });
};

 toggleCircleVisibility = (index) => {
   this.setState((prevState) => ({
     visibleCircleIndex: prevState.visibleCircleIndex === index ? null : index,
   }));
 };


 toggleFourthTableVisibility = (detailIndex, event) => {
   event.stopPropagation();
   this.setState({ visibleFourthTableIndex: this.state.visibleFourthTableIndex === detailIndex ? null : detailIndex });
 };


 calculateDateRange = (periodType) => {
   const currentDate = new Date();
   let startDate = new Date();
   let endDate = new Date();


   switch (periodType) {
     case 'TODAY':
       startDate = currentDate;
       break;
     case 'LAST 3 DAYS':
       startDate.setDate(currentDate.getDate() - 3);
       break;
     case 'LAST 5 DAYS':
       startDate.setDate(currentDate.getDate() - 5);
       break;
     case 'LAST 10 DAYS':
       startDate.setDate(currentDate.getDate() - 10);
       break;
     case 'LAST 15 DAYS':
       startDate.setDate(currentDate.getDate() - 15);
       break;
     case 'LAST 30 DAYS':
       startDate.setDate(currentDate.getDate() - 30);
       break;
     case 'CUSTOM':
       startDate = this.state.startDate;
       endDate = this.state.endDate;
       break;
     default:
       startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
       endDate = currentDate;
   }


   return { startDate, endDate };
 }


 dgCluster_reports_new = () => {
   var user_id = localStorage.getItem("user_id");
   ApiUrlCall.cluster_Name_new(user_id).then(response => {
 
  //   console.log("cluster_Name_new", response)
     if (response.data.response_code === 200) {
       const clusters = response.data.data;
       const defaultCircle = clusters.find(circle => circle.circlename === 'Rajasthan');
       this.setState({
         vehicleLiveTrack_cluster: clusters,
         selectedCircle: defaultCircle ? defaultCircle.circlename : clusters[0].circlename,
         indusdata: response.data.data,
         areaData: response.data.data,
       });
     }
   }).catch(err => {
     console.log("Error fetching cluster data", err);
   });
 };


 imd_dashbord = () => {
   this.setState({ isLoading: true }); // Show the spinner
   const { generateReport, selectedCircle, isStartDateSelected, isEndDateSelected, selectedArea, selectedCluster, selectedFse } = this.state;
   const user_id = localStorage.getItem("user_id");
   this._isMounted = true;
   const { startDate, endDate } = this.calculateDateRange(this.state.period_type);
   const past_date = CommonFunction.createServerDateTime(isStartDateSelected ? this.state.startDate : startDate, this.state.sendStartTime);
   const current_date = CommonFunction.createServerDateTime(isEndDateSelected ? this.state.endDate : endDate, this.state.sendEndTime);


   const dg_report_details = {
     user_id: user_id,
     start_time: past_date,
     end_time: current_date,
     circle: selectedCircle,
     area: selectedArea,
     cluster: selectedCluster,
     fse: selectedFse,
   };


   JSON.stringify(dg_report_details);
 // console.log("master report details", dg_report_details)
   ApiUrlCall.imd_dashbord_api(dg_report_details)
     .then((response) => {


    //  console.log("master report details ", response)


       this.setState({ isLoading: false }); // Hide the spinner
       if (response.status === 200) {   

         const rajasthan_dash_data = response.data.data.map((cluster) => ({
           circlenames: cluster.circlename,
           total_fleet: cluster.total_dgs_in_aom,
           total_imd_used: cluster.total_aom_dg_on_count,
           imd_idle: cluster.total_aom_dg_never_turned_on_count,
           unique_Sites_where_IMD_Used: cluster.total_unique_sites,
           total_days: cluster.total_dg_utilization_count,
           cluster_list: cluster.cluster_list.map((item) => ({
             aom: item.aom,
             fse_count: item.fse_count,
             total_dgs_in_aom: item.total_dgs_in_aom,
             total_aom_dg_never_turned_on_count: item.total_aom_dg_never_turned_on_count,
             total_aom_dg_on_count: item.total_aom_dg_on_count,
             total_unique_sites: item.total_unique_sites,
             total_dg_utilization_count: item.total_dg_utilization_count,
             fse_details: item.fse_details.map((fse) => ({
               fse_name: fse.fse_name,
               total_fse_dg_on_count: fse.total_fse_dg_on_count,
               fse_total_unique_sites: fse.total_unique_sites,
               total_dg_utilization_count: fse.total_dg_utilization_count,
               fse_dg_never_turned_on_count: fse.fse_dg_never_turned_on_count,
               serial_count: fse.serial_count,
               details: fse.details.map((detail) => ({
                 serial_number: detail.serial_number,
                 vehicle_name: detail.fse_name,
                 dg_utilization: detail.dg_utilization,
                 site_name: detail.site_name,
                 battery_percentage: detail.battery_percentage,
                 dg_details: Array.isArray(detail.dg_details) ? detail.dg_details : []
               })),
             })),
           })),
         }));
         this.setState({ rajasthan_dash_data });
       }
     }).catch(err => {
       this.setState({
         openAlert: true,
         message: "Kindly select valid date"
       })
     })
 }


 generateExcel = (data) => {
   const workbook = XLSX.utils.book_new();
   const worksheetData = [];


   // Define the headers
   worksheetData.push([
     'Circle Name',
     'Indus ID',
   //   'site Name',
    //  'Area Name',
     'AOM Name',
     'FSE Name',
     'IMD Name',
     'Nearest Site distance ( Mtr)',
     'Start Date',
     'End Date',
     'Distance ( KM)',
     'DG Move',
     'DG ON (min)',
     'DG OFF (min)',
     'GPS Battery Status',


   ]);


   // Iterate over the data and flatten the nested structure
   data.forEach((cluster) => {
     cluster.cluster_list.forEach((aom) => {
       aom.fse_details.forEach((fse) => {
         fse.details.forEach((detail) => {
           detail.dg_details.forEach((dgDetail) => {
             worksheetData.push([


               cluster.circlenames,
               dgDetail.nearest_location_id,
             // dgDetail.site_name,
            //   dgDetail.area_name,
               dgDetail.aom,
               dgDetail.fse_name,
               dgDetail.imd_name,
               dgDetail.distance,
               this.formatDateTime(dgDetail.Start_time),
               this.formatDateTime(dgDetail.End_Time),
               dgDetail.km,
               dgDetail.dg_move,
                dgDetail.dg_on_time,
                dgDetail.dg_off_time,
                dgDetail.battery_percentage,




               //cluster.total_fleet,
             //  cluster.total_imd_used,
             //  cluster.imd_idle,
             //  cluster.unique_Sites_where_IMD_Used,
             //  cluster.total_days,
             //  aom.aom,
             //  aom.total_dgs_in_aom,
             ///  aom.total_aom_dg_on_count,
               // aom.total_aom_dg_never_turned_on_count,
               // aom.total_unique_sites,
               // aom.total_dg_utilization_count,
               // fse.fse_name,
               // fse.serial_count,
               // fse.total_fse_dg_on_count,
               // fse.fse_dg_never_turned_on_count,
               // fse.fse_total_unique_sites,
               // fse.total_dg_utilization_count,
              
          
             //  dgDetail.distance,
              // this.formatDateTime(dgDetail.last_charging_time)
             ]);
           });
         });
       });
     });
   });


   const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
   XLSX.utils.book_append_sheet(workbook, worksheet, 'MasterDashboardData');
   XLSX.writeFile(workbook, 'IMD_DashboardData.xlsx');
 };


 showCalendar = (e) => {
   var calendardiv = e.currentTarget.parentNode.parentNode.nextSibling;
   $(calendardiv).toggle();
 }


 showStartDate = (e) => {
   $(e.currentTarget).next().slideToggle(200, "swing");
 }


 dateFormate = (rdate) => {
   var date = new Date(rdate);
   var year = date.getFullYear();
   var month = date.getMonth() + 1;
   var day = date.getDate();
   if (day < 10) {
     day = '0' + day;
   }
   if (month < 10) {
     month = '0' + month;
   }
   var convertDate = day + '/' + month + '/' + year;
   return convertDate;
 }


 showTimePicker = (e) => {
   $(e.currentTarget).next().slideToggle();
 }


 selectVehicleOpt = (list) => {
   var input = $('#vehicles_select').find('input');
   for (let j = 0; j < input.length; j++) {
     const element = input[j];
     for (let i = 0; i < list.length; i++) {
       const element1 = list[i];
       if ($(element).val() === element1) {
         $(element).prop('checked', true);
       } else {
       }
     }
   }
 }


 handleChecks() {
   this.setState({ isChecked: !this.state.isChecked });
 }


 hideAllDropDown = () => {
   $('#vehicles_select').parent().hide();
   $('#end_date_cal').hide();
   $('#start_date_cal').hide();
   $('#123').parent().hide();
   $('#456').parent().hide();
   this.setState({
     showStartTime: false,
     showEndTime: false,
   });
 }


 create_Report = () => {
   const { startDate, endDate, selectedCircle, selectedArea, selectedCluster, selectedFse } = this.state;


   // Validation to ensure the date range is not more than 90 days
   var Difference_In_Time = endDate.getTime() - startDate.getTime();
   var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));


   if (Difference_In_Days > 90) {
       this.setState({
           openAlert: true,
           message_vehicle: "Not allowed to select more than 90 days"
       });
       return;
   }


   this.setState({ isLoading: true, message_vehicle: 'Generating report...' });


   // Function to format the date to the expected format: 'YYYY-MM-DD HH:mm:ss'
   const formatDateTime = (date) => {
       const year = date.getFullYear();
       const month = String(date.getMonth() + 1).padStart(2, '0');  // Adding leading zero for months
       const day = String(date.getDate()).padStart(2, '0');          // Adding leading zero for days
       const hours = String(date.getHours()).padStart(2, '0');
       const minutes = String(date.getMinutes()).padStart(2, '0');
       const seconds = String(date.getSeconds()).padStart(2, '0');
      
       return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
   };


   const formattedStartDate = formatDateTime(startDate);
   const formattedEndDate = formatDateTime(endDate);


   const user_id = localStorage.getItem("user_id");
   const dg_report_details = {
       user_id: user_id,
       start_time: formattedStartDate,
       end_time: formattedEndDate,
       area: selectedArea,
       cluster: selectedCluster,
       fse: selectedFse
   };


   // Debugging message to track the API request
   this.setState({
       message_vehicle: `Requesting ${selectedCircle} report from API with data: ${JSON.stringify(dg_report_details)}`
   });


   if (selectedCircle === 'Mumbai') {
       ApiUrlCall.mumbai_dashbord_api(dg_report_details)
           .then(response => {
             //  console.log("Mumbai report response", response);
               const mumbai_dash_data_1 = response.data.data.bifurcated_data_start_only.map((data) => ({
                date: data.date,
                total_indus_id: data.total_indus_id,
                unique_ids_count: data.unique_ids_count,
                duration_sum: data.duration_sum,
                clusters: data.clusters.map((cluster) => ({
                  cluster: cluster.cluster,
                  count: cluster.count,
                  unique_ids_count:cluster.unique_ids_count ,
                  unique_first_index_count:cluster.unique_first_index_count,
                  duration_sum: cluster.duration_sum,
                  fse: cluster.fse.map((fse) => ({
                    fse: fse.fse,
                    indus_id_count: fse.indus_id_count,
                    unique_indus_id:fse.unique_indus_id,
                    duration_sum_fse: fse.duration_sum_fse,
                   
                    technicians: fse.technicians.map((technician) => ({
                      technician: technician.technician,
                      unique_indus_id_tse: technician.unique_indus_id_tse,
                      indus_id_count_tse  : technician.indus_id_count_tse ,
                      duration_sum_tse: technician.duration_sum_tse
                    }))
                  }))
                })),
                entries: data.entries.map((entry) => ({
                  name: entry.name,
                  indus_id: entry.indus_id,
                  start_time: entry.start_time,
                  end_time: entry.end_time,
                  diff: entry.diff,
                })),
              }));


               this.setState({
                mumbai_dash_data: mumbai_dash_data_1, // Ensure data is available
                   isLoading: false,
                   message_vehicle: 'Mumbai report generated successfully'
               });


               if (!response.data || !response.data.data) {
                   this.setState({
                       message_vehicle: 'No data received from Mumbai API'
                   });
               }


           })
           .catch(error => {
               console.error("Error in API call", error);  // Log error to track if API fails
               this.setState({
                   isLoading: false,
                   openAlert: true,
                   message_vehicle: `Error fetching Mumbai report: ${error.message}`
               });
           });
   } else if (selectedCircle === 'Rajasthan') {
       this.imd_dashbord();
   } else {
       this.setState({
           openAlert: true,
           message_vehicle: "Please select a valid circle"
       });
   }
};



 reset = () => {
   this.setState({ dateRange: initialState });
 }


 showStartDate = (e) => {
   $(e.currentTarget).next().slideToggle(200, "swing");
   $('#end_date_cal').slideUp();
   this.setState({
     showStartTime: false,
     showEndTime: false,
   });
 }


 showEndDate = (e) => {
   $(e.currentTarget).next().slideToggle(200, "swing");
   $('#start_date_cal').slideUp();
   this.setState({
     showStartTime: false,
     showEndTime: false,
   });
 }


 doubleDigit = (value) => {
   if (value <= 9) {
     return '0' + value;
   } else {
     return value;
   }
 }


 selectStartTime = (newTime) => {
   this.setState({
     startTime: newTime.formatted12,
     sendStartTime: newTime.formatted24
   });
 }


 showStartTimeContent = () => {
   $('#end_date_cal').slideUp();
   $('#start_date_cal').slideUp();
   if (this.state.showStartTime) {
     this.setState({
       showStartTime: false,
       showEndTime: false
     });
   } else {
     this.setState({
       showStartTime: true,
       showEndTime: false
     });
   }
 }


 selectEndTime = (newTime) => {
   this.setState({
     endTime: newTime.formatted12,
     sendEndTime: newTime.formatted24
   });
 }


 showEndTimeContent = () => {
   $('#end_date_cal').slideUp();
   $('#start_date_cal').slideUp();
   if (this.state.showEndTime) {
     this.setState({
       showEndTime: false,
       showStartTime: false
     });
   } else {
     this.setState({
       showEndTime: true,
       showStartTime: false
     });
   }
 }


 handlePeriodChange = (e) => {
   const periodType = e.target.value;
   const { startDate, endDate } = this.calculateDateRange(periodType);
   this.setState({
     period_type: periodType,
     startDate,
     endDate,
     isStartDateSelected: false,
     isEndDateSelected: false
   }, () => {
     this.create_Report();
   });
 };


 StartdateClicked = date => {
   this.setState({
     startDate: date,
     isStartDateSelected: true,
     period_type: 'CUSTOM',
     showCalendar: false
   });
 }


 EnddateClicked = date => {
   this.setState({
     endDate: date,
     isEndDateSelected: true,
     period_type: 'CUSTOM',
     showCalendar: false
   });
 }


 showStartDate = (e) => {
   $(e.currentTarget).next().slideToggle(200, "swing");
   this.setState({ showCalendar: true });
 }


 showEndDate = (e) => {
   $(e.currentTarget).next().slideToggle(200, "swing");
   this.setState({ showCalendar: true });
 }


 formatDateTime(dateTimeString) {
   const date = new Date(dateTimeString);
   const year = date.getFullYear();
   const month = String(date.getMonth() + 1).padStart(2, '0');
   const day = String(date.getDate()).padStart(2, '0');
   let hours = date.getHours();
   const minutes = String(date.getMinutes()).padStart(2, '0');
   const seconds = String(date.getSeconds()).padStart(2, '0');
   const ampm = hours >= 12 ? 'PM' : 'AM';
   hours = hours % 12;
   hours = hours ? hours : 12; // the hour '0' should be '12'
   hours = String(hours).padStart(2, '0');
   return `${year}/${month}/${day} ${hours}:${minutes}:${ampm}`;
 }


 handleCircleChange = (event) => {
  const selectedCircle = event.target.value;
  
  // Set the selectedCircle state and call the respective report function
  this.setState({ selectedCircle }, () => {
    if (selectedCircle === 'Rajasthan') {
      this.imd_dashbord();  // Call Rajasthan report
    } else if (selectedCircle === 'Mumbai') {
      this.create_Report();  // Call Mumbai report
    }
  });
};


//  handleCircleChange = (event) => {
//    const selectedCircle = event.target.value;
//    this.setState({
//        selectedCircle,
//        selectedArea: '',
//        selectedCluster: '',
//        selectedFse: ''
//    });
// };
 handleAreaChange = (event) => {
   this.setState({
     selectedArea: event.target.value,
     selectedCluster: '',
     selectedFse: ''
   });
 };




 handleClusterChange = (event) => {
   this.setState({ selectedCluster: event.target.value, selectedFse: '' });
 };


 handleFseChange = (event) => {
   this.setState({ selectedFse: event.target.value });
 };

 formatDateTimesnew(dateTimeString) {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = String(hours).padStart(2, '0');
  return `${year}/${month}/${day} `;
}


 render() {
   const { selectedCircle, mumbai_dash_data,rajasthan_dash_data, message_vehicle } = this.state;
    return (
       <div>
            {selectedCircle === 'Mumbai' && this.renderMumbaiReport()}
           {selectedCircle === 'Rajasthan' && this.renderRajasthanReport()}
       </div>
   );
 }



// Render function for Rajasthan report
renderRajasthanReport() {
   const { rajasthan_dash_data, visibleAomIndex, visibleFseIndex, visibleFourthTableIndex, redirectToReferrer, periodTypeList } = this.state;
   const { selectedCircle, selectedArea, selectedCluster, selectedFse, areaData } = this.state;
   const { t } = this.props;
  //console.log(rajasthan_dash_data,"rajasthan_dash_data@@@")

   if (redirectToReferrer === true) {
     return <Redirect to={{ pathname: "/sheduler_report", state: { id: 24 } }} />;
   }


   var minDate = new Date();
   minDate.setDate(minDate.getDate() - 90);


   var period_type_list = periodTypeList.map(period_type_list => {
     return (<option key={period_type_list.id} value={period_type_list.name}>{period_type_list.name}</option>);
   });


   return (
     <div className='Main_div'>
       <div className="report_filter_div">
         <Grid spacing={3} container>
           <Grid item xs>
             <p>{t('ct.startdate')}</p>
             <div className="input_div" onClick={this.showStartDate}>
               <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={this.dateFormate(this.state.startDate)} className="journey_input"></input>
               <img src="images/fill-111.png" className="vd_journey_icon"></img>
             </div>
             {this.state.showCalendar && (
               <div className="calendar_parent" id="start_date_cal">
                 <Calendar
                   selectRange={false}
                   onChange={this.StartdateClicked}
                   value={this.state.startDate}
                   maxDate={this.state.date}
                   minDate={minDate}
                 />
               </div>
             )}
           </Grid>
           <Grid item xs>
             <p>Start Time</p>
             <div onClick={this.showStartTimeContent} className="time_div">
               <p>{this.state.startTime}</p>
             </div>
             {this.state.showStartTime &&
               <div style={{ position: "absolute", zIndex: "9" }}>
                 <Timekeeper
                   time={this.state.startTime}
                   switchToMinuteOnHourSelect
                   onDoneClick={() => {
                     this.setState({
                       showStartTime: false
                     });
                   }}
                   onChange={this.selectStartTime}
                 />
               </div>
             }
           </Grid>
           <Grid item xs>
             <p>{t('ct.enddate')}</p>
             <div className="input_div" onClick={this.showEndDate}>
               <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={this.dateFormate(this.state.endDate)} className="journey_input"></input>
               <img src="images/fill-111.png" className="vd_journey_icon"></img>
             </div>
             {this.state.showCalendar && (
               <div className="calendar_parent" id="end_date_cal">
                 <Calendar
                   selectRange={false}
                   onChange={this.EnddateClicked}
                   value={this.state.endDate}
                   maxDate={this.state.date}
                   minDate={minDate}
                 />
               </div>
             )}
           </Grid>
           <Grid item xs>
             <p>End Time</p>
             <div onClick={this.showEndTimeContent} className="time_div">
               <p>{this.state.endTime}</p>
             </div>
             {this.state.showEndTime &&
               <div style={{ position: "absolute", zIndex: "9" }}>
                 <Timekeeper
                   time={this.state.endTime}
                   switchToMinuteOnHourSelect
                   onDoneClick={() => {
                     this.setState({
                       showEndTime: false
                     });
                   }}
                   onChange={this.selectEndTime}
                 />
               </div>
             }
           </Grid>
           <Grid item xs>
             <p>Select a Period</p>
             <select id={'period_'} value={this.state.period_type} className="time_div" style={{ width: "100%" }}
               onChange={this.handlePeriodChange}>
               <option value='CUSTOM'>Select Period Type</option>
               {period_type_list}
             </select>
           </Grid>


           <Grid item xs >
             <p>Select a Circle</p>
             <select value={selectedCircle} onChange={this.handleCircleChange} className="time_div" style={{ width: "100%" }}>
               <option value="">Select Circle</option>
               {areaData.map(circle => (
                 <option key={circle.circlename} value={circle.circlename}>{circle.circlename}</option>
               ))}
             </select>
           </Grid>


           <Grid item xs>
             <p>Select a Area</p>
             <select value={selectedArea} onChange={this.handleAreaChange} className="time_div" style={{ width: "100%" }}>
               <option value="">Select Area</option>
               {areaData.find(circle => circle.circlename === selectedCircle)?.areas.map(area => (
                 <option key={area.areaname} value={area.areaname}>{area.areaname}</option>
               ))}
             </select>
           </Grid>


           <Grid item xs >
             <p>Select a AOM</p>
             <select value={selectedCluster} onChange={this.handleClusterChange} className="time_div" style={{ width: "100%" }}>
               <option value="">Select AOM</option>
               {areaData.find(circle => circle.circlename === selectedCircle)?.areas.find(area => area.areaname === selectedArea)?.clusters.map(cluster => (
                 <option key={cluster.clustername} value={cluster.clustername}>{cluster.clustername}</option>
               ))}
             </select>
           </Grid>


           <Grid item xs>
             <p>Select a FSE</p>
             <select value={selectedFse} onChange={this.handleFseChange} className="time_div" style={{ width: "100%" }}>
               <option value="">Select FSE</option>
               {areaData.find(circle => circle.circlename === selectedCircle)?.areas.find(area => area.areaname === selectedArea)?.clusters.find(cluster => cluster.clustername === selectedCluster)?.fse_names.map(fse => (
                 <option key={fse} value={fse}>{fse}</option>
               ))}
             </select>
           </Grid>


           <Grid item xs>
             <label>&nbsp;</label>
             <button className="btn orange-btn btn-block" style={{ marginTop: "6px" }} onClick={this.create_Report}>{t('ars.generate')}</button>
           </Grid>
           <Grid item xs onClick={() => this.generateExcel(this.state.rajasthan_dash_data)} >
             <img src="images/group-6-copy-2.png" style={{ marginRight: "5px" }}></img>
             <label className="BSC_semi_bold18">{t('ars.excel')}</label>
           </Grid>
         </Grid>
       </div>
       <div className="select_aom_box">
         <table className="summary-table">
           <thead>
             <tr>
               <th>Circle name</th>
               <th>Total Fleet</th>
               <th>Total IMD Used</th>
               <th>IMD Idle</th>
               <th>Unique Sites where IMD Used</th>
               <th>Total Days</th>
             </tr>
           </thead>
           <tbody>
             {rajasthan_dash_data.map((cluster, clusterIndex) => (
               <tr key={clusterIndex} onClick={() => this.toggleCircleVisibility(clusterIndex)}>
                 <td>{cluster.circlenames}</td>
                 <td>{cluster.total_fleet}</td>
                 <td>{cluster.total_imd_used}</td>
                 <td>{cluster.imd_idle}</td>
                 <td>{cluster.unique_Sites_where_IMD_Used}</td>
                 <td>{cluster.total_days}</td>
               </tr>
             ))}
           </tbody>
         </table>
         {rajasthan_dash_data.map((cluster, clusterIndex) => (
           this.state.visibleCircleIndex === clusterIndex && (
             <table key={`details-${clusterIndex}`} className="summary-table">
               <thead>
                 <tr>
                   <th>AOM</th>
                   <th>Total Fleet</th>
                   <th>Total IMD Used</th>
                   <th>IMD Idle</th>
                   <th>Unique Sites where IMD Used</th>
                   <th>Total Days</th>
                 </tr>
               </thead>
               <tbody>
                 {cluster.cluster_list.map((aom, aomIndex) => (
                   <React.Fragment key={aomIndex}>
                     <tr className={`aom_list ${this.state.visibleAomIndex === aomIndex ? 'selected' : ''}`} onClick={() => this.toggleVisibility(aomIndex)} >
                       <td>{aom.aom}</td>
                       <td>{aom.total_dgs_in_aom}</td>
                       <td>{aom.total_aom_dg_on_count}</td>
                       <td>{aom.total_aom_dg_never_turned_on_count}</td>
                       <td>{aom.total_unique_sites}</td>
                       <td>{aom.total_dg_utilization_count}</td>
                     </tr>
                     {this.state.visibleAomIndex === aomIndex && (
                       <tr>
                         <td colSpan="6">
                           <table className="fse_table">
                             <thead>
                               <tr>
                                 <th>FSE Name</th>
                                 <th>Total Fleet</th>
                                 <th>Total IMD Used</th>
                                 <th>IMD Idle</th>
                                 <th>Unique Sites where IMD Used</th>
                                 <th>Total Days</th>
                               </tr>
                             </thead>
                             <tbody>
                               {aom.fse_details.map((fse, fseIndex) => (
                                 <React.Fragment key={fseIndex}>
                                   <tr className={`fse_list ${this.state.visibleFseIndex === fseIndex ? 'selected' : ''}`} onClick={(event) => this.toggleFseVisibility(fseIndex, event)}>
                                     <td className="fse_dg_name_count p_margin0">{fse.fse_name}</td>
                                     <td className="fse_dg_count p_margin0">{fse.serial_count}</td>
                                     <td className="fse_start_count p_margin0">{fse.total_fse_dg_on_count}</td>
                                     <td className="fse_stop_count p_margin0">{fse.fse_dg_never_turned_on_count}</td>
                                     <td className="fse_start_count p_margin0">{fse.fse_total_unique_sites}</td>
                                     <td className="fse_dg_count p_margin0">{fse.total_dg_utilization_count}</td>
                                   </tr>
                                   {this.state.visibleFseIndex === fseIndex && (
                                     <tr>
                                       <td colSpan="6">
                                         <table className="third_table">
                                           <thead>
                                             <tr>
                                               <th>Start Date</th>
                                               <th>End Date</th>
                                               <th>IMD Name</th>
                                               <th>AOM</th>
                                               <th>Nearest Site distance ( Mtr)</th>
                                               <th>FSE Name</th>
                                               <th>Indus ID</th>
                                               {/* <th>Site Name</th> */}
                                               {/* <th>Area Name</th> */}
                                               <th>Distance ( KM)</th>
                                               <th>DG Move</th>
                                               <th>DG ON (min)</th>
                                               <th>DG OFF (min)</th>
                                               <th>GPS Battery Status</th>
                                           
                                             </tr>
                                           </thead>
                                           <tbody>
                                             {fse.details.map((detail, detailIndex) => (
                                               <React.Fragment key={detailIndex}>
                                                 {detail.dg_details.map((dgDetail, dgIndex) => (
                                                   <tr key={dgIndex} onClick={(event) => this.toggleFourthTableVisibility(dgIndex, event)}>
                                                     <td>{this.formatDateTime(dgDetail.Start_time)}</td>
                                                     <td>{this.formatDateTime(dgDetail.End_Time)}</td>
                                                     <td>{dgDetail.imd_name}</td>
                                                     <td>{dgDetail.aom}</td>
                                                     <td>{dgDetail.distance}</td>
                                                     <td>{dgDetail.fse_name}</td>
                                                     <td>{dgDetail.nearest_location_id}</td>
                                                     {/* <td> {dgDetail.site_name} </td> */}
                                                     {/* <td> {dgDetail.area_name}  </td> */}
                                                     <td>{dgDetail.km !== undefined && dgDetail.km !== ' ' ? dgDetail.km : 0}</td>
                                                     <td>{dgDetail.dg_move}</td>
                                                     <td>{Math.ceil(dgDetail.dg_on_time)}</td>
                                                     <td>{Math.ceil(dgDetail.dg_off_time)}</td>
                                                     <td>{dgDetail.battery_percentage} %</td>
                                                  
                                                   </tr>
                                                 ))}
                                               </React.Fragment>
                                             ))}
                                           </tbody>
                                         </table>
                                       </td>
                                     </tr>
                                   )}
                                 </React.Fragment>
                               ))}
                             </tbody>
                           </table>
                         </td>
                       </tr>
                     )}
                   </React.Fragment>
                 ))}
               </tbody>
             </table>
           )
         ))}
       </div>


       {this.state.isLoading && (
         <div id="scroll">
           <div id="scrolling" style={{ display: 'flex', justifyContent: 'center' }}>
             <RotatingLines
               visible={true}
               height="60"
               width="60"
               strokeColor="grey"
               strokeWidth="5"
               animationDuration="0.75"
               ariaLabel="rotating-lines-loading"
             />
           </div>
         </div>
       )}


       {/* {this.state.isLoading && (
         <div id='scroll'>
           <div id='scrolling' style={{ display: 'flex', justifyContent: 'center' }}>
             <RotatingLines
               visible={true}
               height="60"
               width="60"
               strokeColor="grey"
               strokeWidth="5"
               animationDuration="0.75"
               ariaLabel="rotating-lines-loading"
               wrapperStyle={{}}
               wrapperClass=""
             />
           </div>
         </div>
       )} */}
       <Snackbar
         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
         autoHideDuration={1800}
         onClose={(e, reason) => {
           if (reason === 'clickaway') {
             return;
           }
           this.setState({ openAlert: false });
         }}
         open={this.state.openAlert}
         message={this.state.message_vehicle}
       />
     </div>
   );
}
// // Render function for Mumbai report testing
// Render function for Mumbai report
renderMumbaiReport() {
 const { mumbai_dash_data, visibleClusterIndex, redirectToReferrer, periodTypeList, visibleFseIndex, visibleTechnicianIndex, selectedCircle, areaData } = this.state;
 const { t } = this.props;

// console.log(mumbai_dash_data,'mumbai_dash_data>>>>>')

 if (redirectToReferrer === true) {
   return <Redirect to={{ pathname: "/sheduler_report", state: { id: 24 } }} />;
 }


 var minDate = new Date();
 minDate.setDate(minDate.getDate() - 90);


 var period_type_list = periodTypeList.map(period_type_list => {
   return (<option key={period_type_list.id} value={period_type_list.name}>{period_type_list.name}</option>);
 });


 return (
   <div className="Main_div">
     <div className="report_filter_div">
       <Grid spacing={3} container>
         <Grid item xs>
           <p>{t('ct.startdate')}</p>
           <div className="input_div" onClick={this.showStartDate}>
             <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={this.dateFormate(this.state.startDate)} className="journey_input"></input>
             <img src="images/fill-111.png" className="vd_journey_icon"></img>
           </div>
           {this.state.showCalendar && (
             <div className="calendar_parent" id="start_date_cal">
               <Calendar
                 selectRange={false}
                 onChange={this.StartdateClicked}
                 value={this.state.startDate}
                 maxDate={this.state.date}
                 minDate={minDate}
               />
             </div>
           )}
         </Grid>
         <Grid item xs>
           <p>Start Time</p>
           <div onClick={this.showStartTimeContent} className="time_div">
             <p>{this.state.startTime}</p>
           </div>
           {this.state.showStartTime &&
             <div style={{ position: "absolute", zIndex: "9" }}>
               <Timekeeper
                 time={this.state.startTime}
                 switchToMinuteOnHourSelect
                 onDoneClick={() => {
                   this.setState({
                     showStartTime: false
                   });
                 }}
                 onChange={this.selectStartTime}
               />
             </div>
           }
         </Grid>
         <Grid item xs>
           <p>{t('ct.enddate')}</p>
           <div className="input_div" onClick={this.showEndDate}>
             <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={this.dateFormate(this.state.endDate)} className="journey_input"></input>
             <img src="images/fill-111.png" className="vd_journey_icon"></img>
           </div>
           {this.state.showCalendar && (
             <div className="calendar_parent" id="end_date_cal">
               <Calendar
                 selectRange={false}
                 onChange={this.EnddateClicked}
                 value={this.state.endDate}
                 maxDate={this.state.date}
                 minDate={minDate}
               />
             </div>
           )}
         </Grid>
         <Grid item xs>
           <p>End Time</p>
           <div onClick={this.showEndTimeContent} className="time_div">
             <p>{this.state.endTime}</p>
           </div>
           {this.state.showEndTime &&
             <div style={{ position: "absolute", zIndex: "9" }}>
               <Timekeeper
                 time={this.state.endTime}
                 switchToMinuteOnHourSelect
                 onDoneClick={() => {
                   this.setState({
                     showEndTime: false
                   });
                 }}
                 onChange={this.selectEndTime}
               />
             </div>
           }
         </Grid>
         <Grid item xs>
           <p>Select a Circle</p>
           <select value={selectedCircle} onChange={this.handleCircleChange} className="time_div" style={{ width: "100%" }}>
             <option value="">Select Circle</option>
             {areaData.map(circle => (
               <option key={circle.circlename} value={circle.circlename}>{circle.circlename}</option>
             ))}
           </select>
         </Grid>
         <Grid item xs>
           <label>&nbsp;</label>
           <button className="btn orange-btn btn-block" style={{ marginTop: "6px" }} onClick={this.create_Report}>{t('ars.generate')}</button>
         </Grid>
         <Grid item xs onClick={() => this.generateExcel(this.state.mumbai_dash_data)} >
           <img src="images/group-6-copy-2.png" style={{ marginRight: "5px" }}></img>
           <label className="BSC_semi_bold18">{t('ars.excel')}</label>
         </Grid>
       </Grid>
     </div>


     <div className="select_aom_box">
       <table className="summary-table">
         <thead>
           <tr>
             <th>Date</th>
             <th>Total Indus ID</th>
             <th>Unique Indus ID Count</th>
             <th>DG ON Duration (minutes)</th>
           </tr>
         </thead>
         <tbody>
          
           {mumbai_dash_data.map((data, index) => (
             <React.Fragment key={index}>
               <tr onClick={() => this.toggleClusterVisibility(index, data.clusters)}>
                 <td>{this.formatDateTimesnew(data.date)}</td>
                 <td>{data.total_indus_id}</td>
                 <td>{data.unique_ids_count}</td>
                 <td>{data.duration_sum}</td>
               </tr>
               {visibleClusterIndex === index && (
                 <tr>
                   <td colSpan="4" style={{ padding: 0 }}>
                     <table className="cluster-table">
                       <thead>
                         <tr>
                           <th>Cluster Name</th>
                           <th>Indus ID Count</th>
                           <th>Unique Indus ID Count</th>
                           <th>DG ON Duration (minutes)</th>
                         </tr>
                       </thead>
                       <tbody>
                         {data.clusters.map((cluster, clusterIndex) => (
                           <React.Fragment key={clusterIndex}>
                             <tr onClick={() => this.toggleFseVisibility(clusterIndex, cluster.fse)}>
                               <td>{cluster.cluster}</td>
                               <td>{cluster.count}</td>
                               <td>{cluster.unique_first_index_count}</td>
                               <td>{cluster.duration_sum}</td>
                             </tr>
                             {visibleFseIndex === clusterIndex && (
                               <tr>
                                 <td colSpan="4" style={{ padding: 0 }}>
                                   <table className="fse-table">
                                     <thead>
                                       <tr>
                                         <th>FSE Name</th>
                                         <th>Indus ID Count</th>
                                         <th>Unique Indus ID Count</th>
                                         <th> DG ON Duration (minutes)</th>
                                       </tr>
                                     </thead>
                                     <tbody>
                                       {cluster.fse.map((fse, fseIndex) => (
                                         <React.Fragment key={fseIndex}>
                                           <tr onClick={() => this.toggleTechnicianVisibility(fseIndex, fse.technicians)}>
                                             <td>{fse.fse}</td>
                                             <td>{fse.indus_id_count}</td>
                                             <td>{fse.unique_indus_id}</td>
                                             <td>{fse.duration_sum_fse}</td>
                                           </tr>
                                           {visibleTechnicianIndex === fseIndex && (
                                             <tr>
                                               <td colSpan="6" style={{ padding: 0 }}>
                                                 <table className="technician-table">
                                                   <thead>
                                                     <tr>
                                                       <th>Technician Name</th>
                                                       <th>Indus ID Count</th>
                                                       <th>Unique Indus ID Count</th>
                                                       <th>DG ON Duration (minutes)</th>
                                                     </tr>
                                                   </thead>
                                                   <tbody>
                                                     {fse.technicians.map((technician, technicianIndex) => (
                                                       <tr key={technicianIndex}>
                                                         <td>{technician.technician}</td>
                                                         <td>{technician.indus_id_count_tse}</td>
                                                         <td>{technician.unique_indus_id_tse}</td>
                                                         <td>{technician.duration_sum_tse}</td>
                                                       </tr>
                                                     ))}
                                                   </tbody>
                                                 </table>
                                               </td>
                                             </tr>
                                           )}
                                         </React.Fragment>
                                       ))}
                                     </tbody>
                                   </table>
                                 </td>
                               </tr>
                             )}
                           </React.Fragment>
                         ))}
                       </tbody>
                     </table>
                   </td>
                 </tr>
               )}
             </React.Fragment>
           ))}
         </tbody>
       </table>
     </div>


     {this.state.isLoading && (
       <div id="scroll">
         <div id="scrolling" style={{ display: 'flex', justifyContent: 'center' }}>
           <RotatingLines
             visible={true}
             height="60"
             width="60"
             strokeColor="grey"
             strokeWidth="5"
             animationDuration="0.75"
             ariaLabel="rotating-lines-loading"
             wrapperStyle={{}}
             wrapperClass=""
           />
         </div>
       </div>
     )}
     <Snackbar
       anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
       autoHideDuration={1800}
       onClose={(e, reason) => {
         if (reason === 'clickaway') {
           return;
         }
         this.setState({ openAlert: false });
       }}
       open={this.state.openAlert}
       message={this.state.message_vehicle}
     />
   </div>
 );
}

}


export default withTranslation()(IMD_reports);